import {merge} from "lodash";
import {addLine, addText} from "./utilities";

export default function highchartsChart() {
    return {
        chart: null,

        init() {
            setTimeout(() => {
                this.draw(this.$wire);
            }, 0);
        },

        chartContainer: {
            ['@export-chart.window'](event) {
                if (this.chart.renderTo.id !== event.detail.chartId) {
                    return;
                }

                this.chart.exportChartLocal({
                    type: event.detail.type,
                });
            },
            ['@toggle-series.window'](event) {
                const series = this.chart.series.find((series) => {
                    return series.options.id == event.detail.seriesId;
                });

                if (!series) {
                    return;
                }

                if (event.detail.visible) {
                    series.show();
                } else {
                    series.hide();
                }
            },
            ['@highlight-series.window'](event) {
                const seriesId = event.detail.seriesId;

                if (!seriesId) {
                    console.error('Series ID is required to highlight a series');
                    return;
                }

                const isHighlighted = event.detail.highlighted || false;
                const higlightedSeriesState = isHighlighted ? 'hover' : 'normal';
                const otherSeriesState = isHighlighted ? 'inactive' : 'normal';

                this.chart.series.forEach((series) => {
                    if (series.options.id == seriesId) {
                        series.setState(higlightedSeriesState, true);
                    } else {
                        series.setState(otherSeriesState, true);
                    }
                });
            },
        },

        draw(component) {
            const data = component.get('data') || {};
            const customOptions = component.get('options') || {};
            const extras = component.get('extras') || {};

            let categories = {};

            if (data.length !== 0) {
                categories = data[0].categories || {};

                if (data[0].x !== undefined) {
                    categories = data[0].x.map((item) => {
                        return item;
                    });
                }
            }

            const options = {
                chart: {
                    events: {
                        render: function () {
                            let chart = this;

                            if (extras.labels && extras.labels.length > 0) {
                                extras.labels.forEach((label) => {
                                    addText(
                                        chart,
                                        label.key,
                                        label.label,
                                        label.x,
                                        label.y,
                                        label.css || {},
                                        label.attr || {}
                                    );
                                });
                            }

                            if (extras.lines && extras.lines.length > 0) {
                                extras.lines.forEach((line) => {
                                    addLine(
                                        chart,
                                        line.key,
                                        line.x1,
                                        line.y1,
                                        line.x2,
                                        line.y2,
                                        line.style || {}
                                    );
                                });
                            }
                        }
                    }
                },
                exporting: {
                    chartOptions: {
                        title: {
                            text: component.get('title') || '',
                        }
                    },
                },
                series: data,
            };

            if (categories.length > 0) {
                if (options.xAxis === undefined) {
                    options.xAxis = {};
                }

                options.xAxis.categories = categories;
            }

            const combinedOptions = merge(options, customOptions);

            if (extras.showAffinityIndex) {
                combinedOptions.tooltip = combinedOptions.tooltip || {};
                combinedOptions.tooltip.shared = true;

                combinedOptions.tooltip.formatter = function () {
                    let tooltip = '';
                    tooltip += `<span class="chart-tooltip-series-label">${this.key}</span>`;

                    this.points.forEach((point, key) => {
                        tooltip += `<span class="chart-tooltip-point ${key !== 0 ? 'has-small-spacing' : ''}"><span class="chart-tooltip-point-marker" style="color:${point.color};">•</span> <span class="chart-tooltip-point-name">${point.series.name}</span>: <span class="chart-tooltip-point-value">${point.y.toFixed(2)} %</span></span>`;

                        if (key !== 0) {
                            const affinityIndex = this.points[key].point.y / this.points[0].point.y * 100;
                            tooltip += `<span class="chart-tooltip-point"><span style="width: 20px; display:inline-block;"></span><span class="chart-tooltip-point-name">Affinity Index</span>: <span class="chart-tooltip-point-value">${affinityIndex.toFixed(2)}</span></span>`;

                        }
                    });

                    return tooltip;
                };
            }

            if (extras.supportsPowergridBreakdown) {
                combinedOptions.plotOptions = combinedOptions.plotOptions || {};
                combinedOptions.plotOptions.series = combinedOptions.plotOptions.series || {};
                combinedOptions.plotOptions.series.cursor = 'pointer';
                combinedOptions.plotOptions.series.point = {
                    events: {
                        click: function () {
                            window.Livewire.dispatch('openModal', {
                                component: 'modals.powergrid-breakdown', arguments: {
                                    filters: this.options.breakdownFilters,
                                }
                            });
                        }
                    }
                };
            }

            if (extras.supportsGlobalBreakdown) {
                combinedOptions.plotOptions = combinedOptions.plotOptions || {};
                combinedOptions.plotOptions.series = combinedOptions.plotOptions.series || {};
                combinedOptions.plotOptions.series.cursor = 'pointer';
                combinedOptions.plotOptions.series.point = {
                    events: {
                        click: function () {
                            window.Livewire.dispatch('openModal', {
                                component: 'modals.global-breakdown', arguments: {
                                    filters: this.options.globalBreakdownFilters,
                                    modelClass: this.options.modelClass,
                                }
                            });
                        }
                    }
                };
            }

            if (this.chart) {
                this.chart.update(combinedOptions);
            } else {
                this.chart = window.Highcharts.chart(this.$refs.container, combinedOptions);
                this.chart.render();
            }
        }
    };
};
