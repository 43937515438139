export default (values = [], options = [], sort = 'asc') => ({
    values: values,
    options: options,
    search: '',
    open: false,
    sort: sort,
    getDisplayValues() {
        let output = [];

        if (this.values.length > 0) {
            output.push(this.getOptionText(this.values[0]));
        }

        if (this.values.length > 1) {
            output.push('+ ' + (this.values.length - 1));
        }

        return output;
    },
    toggle() {
        if (this.open) {
            this.open = false;
            return;
        }

        this.open = true;
        this.$refs.search.focus();
    },
    maybeRemoveValue() {
        if (this.search === '' && this.values.length > 0) {
            this.values.pop();
        }
    },
    clearValues() {
        this.values = [];
    },
    toggleAll() {
        if (this.values.length === this.options.length) {
            this.clearValues();
            return;
        }

        this.values = this.options.map((option) => option.value);
    },
    getOptionText(value) {
        let option = this.options.filter((option) => option.value == value);

        if (!option || !option[0] || !option[0].label) {
            return '';
        }

        return option[0].label;
    },
    getFilteredOptions() {
        const sortedOptions = this.options
            .sort((a, b) => {
                if (this.sort === 'asc') {
                    return String(a.label).localeCompare(String(b.label));
                } else {
                    return String(b.label).localeCompare(String(a.label));
                }
            });

        if (this.search === '') {
            return sortedOptions;
        }

        return sortedOptions
            .filter((option) => {
                return String(option.label)
                    .toLowerCase()
                    .includes(
                        this.search.toLowerCase()
                    );
            });
    },
    init() {
        this.$watch('values', () => {
            this.search = '';
            this.$refs.search.focus();
        });
    }
});
