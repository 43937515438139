import {merge} from "lodash";

export default function stackedColumn() {
    return {
        chart: null,

        init() {
            setTimeout(() => {
                this.draw(this.$wire);
            }, 0);
        },

        chartContainer: {
            ['@export-chart.window'](event) {
                if (this.chart.renderTo.id !== event.detail.chartId) {
                    return;
                }

                this.chart.exportChartLocal({
                    type: event.detail.type,
                });
            },
        },

        draw(component) {

            const customOptions = component.get('options') || {};
            const data = component.get('data') || {};
            let categories = {};

            if (data.length !== 0) {
                categories = data[0].x.map((item) => {
                    return item;
                });
            }

            const options = {
                chart: {
                    type: 'column',
                },
                xAxis: {
                    categories,
                    type: 'category',
                    crosshair: false,
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    visible: true,
                    title: {
                        enabled: false,
                    }
                },
                tooltip: {
                    shared: true,
                    headerFormat: '<b>{point.x}</b><br/>',
                    pointFormat: '<span style="color:{series.color}">{series.name}</span>: <b>{point.y:.1f}%</b><br/>'
                },
                exporting: {
                    chartOptions: {
                        title: {
                            text: component.get('title') || '',
                        }
                    },
                },
                plotOptions: {
                    column: {
                        stacking: 'normal',
                    }
                },
                series: data,
            };

            if (this.chart) {
                this.chart.update(merge(options, customOptions));
            } else {
                this.chart = window.Highcharts.chart(this.$refs.container, merge(options, customOptions));
                this.chart.render();
            }
        }
    };
};
