export default () => ({
    open: false,
    toggle() {
        if (this.open) {
            return this.close();
        }

        this.$refs.trigger.focus();
        this.open = true;
    },
    close(focusAfter) {
        if (!this.open) {
            return;
        }

        this.open = false;

        if (focusAfter) {
            focusAfter.focus();
        }
    },

    container: {
        ['x-id']() {
            return ['dropdown'];
        },
        ['x-transition:enter']: 'transition ease-out duration-100',
        ['x-transition:enter-start']: 'transform opacity-0 scale-95',
        ['x-transition:enter-end']: 'transform opacity-100 scale-100',
        ['x-transition:leave']: 'transition ease-in duration-75',
        ['x-transition:leave-start']: 'transform opacity-100 scale-100',
        ['x-transition:leave-end']: 'transform opacity-0 scale-95',
        ['x-on:keydown.escape.prevent.stop']() {
            this.close(this.$refs.trigger);
        },
        ['x-on:focusin.window'](event) {
            if (this.$refs.panel && !this.$refs.panel.contains(event.target)) {
                this.close();
            }
        },
    },

    trigger: {
        ['x-ref']: 'trigger',
        [':aria-expanded']() {
            return this.open;
        },
        [':aria-controls']() {
            return this.$id('dropdown');
        },
        [':aria-haspopup']: 'true',
        ['@click.prevent']() {
            this.toggle();
        }
    },

    panel: {
        ['x-ref']: 'panel',
        ['x-transition.origin.top.left']: '',
        ['x-show']() {
            return this.open;
        },
        [':id']() {
            return this.$id('dropdown');
        },
        [':aria-labelledby']() {
            return this.$refs.trigger.id;
        },
        ['@click.outside']() {
            this.close(this.$refs.trigger);
        }
    }
});
