export function exportChart(chartId, type) {
    const event = new CustomEvent('export-chart', {
        detail: {
            type,
            chartId,
        }
    });

    window.dispatchEvent(event);
}

export function downloadSvgChart(id) {
    exportChart(id, 'image/svg+xml');
}

export function downloadPngChart(id) {
    exportChart(id, 'image/png');
}

export function downloadJpgChart(id) {
    exportChart(id, 'image/jpeg');
}


export function addQuadrant(chart, name, x1, y1, x2, y2, attr = {}) {
    maybeDestroyObject(chart, name);

    const x1Pixels = chart.xAxis[0].toPixels(x1, false);
    const y1Pixels = chart.yAxis[0].toPixels(y1, false);
    const x2Pixels = chart.xAxis[0].toPixels(x2, false);
    const y2Pixels = chart.yAxis[0].toPixels(y2, false);

    chart[name] = chart.renderer
        .rect(x1Pixels, y2Pixels, (x2Pixels - x1Pixels), (y1Pixels - y2Pixels), 1)
        .attr({
            ...{
                fill: '#000050',
                opacity: 0.2,
                zIndex: 0
            }, ...attr
        })
        .add();
}

export function addText(chart, key, label, x, y, css = {}, attr = {}) {
    maybeDestroyObject(chart, key);

    const xPixels = chart.xAxis[0].toPixels(x, false);
    const yPixels = chart.yAxis[0].toPixels(y, false);

    chart[key] = chart.renderer.text(label, xPixels, yPixels)
        .css({
            ...{
                fontWeight: 'bold',
                fontSize: '13px',
                color: '#666666',
                zIndex: 5,
            }, ...css
        })
        .attr({
            ...{
                align: 'center'
            },
            ...attr
        })
        .add();
}

export function addLine(chart, key, x1, y1, x2, y2, attr = {}) {
    maybeDestroyObject(chart, key);

    const x1Pixels = chart.xAxis[0].toPixels(x1, false);
    const y1Pixels = chart.yAxis[0].toPixels(y1, false);
    const x2Pixels = chart.xAxis[0].toPixels(x2, false);
    const y2Pixels = chart.yAxis[0].toPixels(y2, false);

    chart[key] = chart.renderer
        .path(['M', x1Pixels, y1Pixels, 'L', x2Pixels, y2Pixels])
        .attr({
            ...{
                'stroke-width': 1,
                stroke: '#eee',
                zIndex: 2
            }, ...attr
        })
        .add();
}

export function maybeDestroyObject(chart, key) {
    if (chart[key]) {
        chart[key].destroy();
    }
}
