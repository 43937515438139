export default (localActiveTab = null) => ({
    localActiveTab,
    tabWidth: '0',
    tabTransform: '0',

    init() {
        let key = this.localActiveTab;

        if (this.localActiveTab === null || this.localActiveTab === '') {
            key = this.$refs.tablist.children[0].id;
        }

        this.$nextTick(() => this.select(key));
    },

    select(id) {
        const tabEl = document.getElementById(id);

        this.localActiveTab = id;
        this.activeTab = id;
        this.tabWidth = tabEl.offsetWidth + 'px';
        this.tabTransform = tabEl.offsetLeft + 'px';
    },

    isSelected(id) {
        return this.localActiveTab === id;
    },

    container: {
        [':style']() {
            return `--tab-bar-item-width: ${this.tabWidth}; --tab-bar-item-transform: ${this.tabTransform};`;
        }
    },
    tab: {
        ['x-on:click']() {
            this.select(this.$el.id);
        },
        [':tabindex']() {
            return this.isSelected(this.$el.id) ? 0 : -1;
        },
        [':aria-selected']() {
            return this.isSelected(this.$el.id);
        },
        [':class']() {
            return this.isSelected(this.$el.id) ? 'text-brand-500' : 'text-wpp-gray-800';
        },
        ['@mousedown.prevent']: '',
    }
});
