import './bootstrap';

import {downloadJpgChart, downloadPngChart, downloadSvgChart} from "./charts/utilities";
import powergridChart from "./charts/powergrid";
import partnerCompatibility from "./charts/partner-compatibility";
import costOfEntryChart from './charts/cost-of-entry';
import valueChart from "./charts/value";
import { Livewire, Alpine } from '../../vendor/livewire/livewire/dist/livewire.esm';
import Clipboard from "@ryangjchandler/alpine-clipboard";
import Tooltip from "@ryangjchandler/alpine-tooltip";
import columnChart from "./charts/column";
import barChart from './charts/bar';
import stackedColumnChart from './charts/stackedColumn';
import lineChart from "./charts/line";
import worldMapChart from "./charts/world-map";
import polarChart from "./charts/polar";
import highchartsChart from "./charts/highchartsChart";
import scatterChart from './charts/scatter';
import stockChart from "./charts/stock";
import '../../vendor/wire-elements/pro/resources/js/overlay-component.js';
import "flatpickr";
import AlpineFloatingUI from '@awcodes/alpine-floating-ui';
import Multiselect from "./alpine-components/multiselect";
import Dropdown from "./alpine-components/dropdown";
import Tabs from "./alpine-components/tabs";
import Copy from "./alpine-components/copy";
import Accordion from "./alpine-components/accordion";
import FlourishChart from "./charts/flourishChart";
import ChartLabels from "./alpine-components/chartLabels";
import { initializeDynamicVideo, voteForQuestion} from "./blings-dynamic-video";
import '@nextapps-be/livewire-sortablejs';
import FileUpload from "./alpine-components/file-upload";
import { connectToParent } from 'penpal';
import WppOpenAuthentication from "./alpine-components/wpp-open-authentication";

window.Fount = {
    charts: {
        downloadSvg: downloadSvgChart,
        downloadJpg: downloadJpgChart,
        downloadPng: downloadPngChart,
    },
    dynamicVideo: {
        init: initializeDynamicVideo,
        voteCallback: voteForQuestion,
    }
};

window.addEventListener('postMessage', event => {
    window.postMessage(event.detail);
});

/**
 * Alpine
 */
Alpine.plugin(Clipboard);
Alpine.plugin(Tooltip);
Alpine.plugin(AlpineFloatingUI);
Alpine.data('charts_column', columnChart);
Alpine.data('charts_bar', barChart);
Alpine.data('charts_line', lineChart);
Alpine.data('charts_stacked_column', stackedColumnChart);
Alpine.data('charts_powergrid', powergridChart);
Alpine.data('charts_partner_compatibility', partnerCompatibility);
Alpine.data('charts_worldmap', worldMapChart);
Alpine.data('charts_cost_of_entry', costOfEntryChart);
Alpine.data('charts_polar', polarChart);
Alpine.data('charts_value', valueChart);
Alpine.data('charts_scatter', scatterChart);
Alpine.data('charts_stock', stockChart);
Alpine.data('charts_generic', highchartsChart);
Alpine.data('multiselect', Multiselect);
Alpine.data('dropdown', Dropdown);
Alpine.data('tabs', Tabs);
Alpine.data('copy', Copy);
Alpine.data('accordion', Accordion);
Alpine.data('flourishChart', FlourishChart);
Alpine.data('chartLabels', ChartLabels);
Alpine.data('fileUpload', FileUpload);
Alpine.data('wppOpenAuthentication', WppOpenAuthentication);

Livewire.start();
