import {merge} from "lodash";

export default function lineChart() {
    return {
        chart: null,

        init() {
            setTimeout(() => {
                this.draw(this.$wire);
            }, 0);
        },

        chartContainer: {
            ['@export-chart.window'](event) {
                if (this.chart.renderTo.id !== event.detail.chartId) {
                    return;
                }

                this.chart.exportChartLocal({
                    type: event.detail.type,
                });
            },
        },

        draw(component) {

            const customOptions = component.get('options') || {};
            const series = component.get('data') || {};

            // This operation first sorts the values
            // by the y value (largest first).
            // Then it sorts all series by the first series order.
            series.forEach(function (name) {
                if (name.sort === undefined || name.sort !== null) {
                    name.data
                        .sort((a, b) => {
                            if (a.y < b.y) {
                                return 1;
                            } else if (a.y > b.y) {
                                return -1;
                            }
                            return 0;
                        })
                        .sort(function (a, b) {
                            const firstOrder = series[0].data.map(data => data.name);

                            const index1 = firstOrder.findIndex(name => name === a.name);
                            const index2 = firstOrder.findIndex(name => name === b.name);

                            if (index1 < index2) {
                                return -1;
                            } else if (index1 > index2) {
                                return 1;
                            }
                            return 0;
                        });
                }
            });

            const options = {
                chart: {
                    type: 'line',
                },
                xAxis: {
                    type: 'category',
                    crosshair: false,
                    labels: {
                        rotation: -45,
                        align: 'right',
                        step: 1
                    },
                },
                yAxis: {
                    min: 0,
                    visible: true,
                    title: {
                        enabled: false,
                    }
                },
                tooltip: {
                    shared: true,
                },
                exporting: {
                    chartOptions: {
                        title: {
                            text: component.get('title') || '',
                        }
                    },
                },
                series,
            };

            if (this.chart) {
                this.chart.update(merge(options, customOptions));
            } else {
                this.chart = window.Highcharts.chart(this.$refs.container, merge(options, customOptions));
                this.chart.render();
            }
        }
    };
};
