export default (name, allowMultipleFiles = false, existingPreviewUrl = null) => ({
    name,
    allowMultipleFiles,
    isDroppingFile: false,
    isUploading: false,
    progress: 0,
    existingPreviewUrl,
    handleFileSelect(event) {
        if (event.target.files.length) {
            this.uploadFiles(event.target.files)
        }
    },
    handleFileDrop(event) {
        if (event.dataTransfer.files.length > 0) {
            this.uploadFiles(event.dataTransfer.files)
        }
    },
    handleSuccessfulUpload(success) {
        this.isUploading = false;
        this.progress = 0;
    },
    handleError(error) {
        this.isUploading = false;
        this.progress = 0;

        console.error('Error uploading file', error);
    },
    handleUploadProgress(event) {
        this.progress = event.detail.progress;
    },
    uploadFiles(files) {
        this.isUploading = true;

        if (this.allowMultipleFiles) {
            this.$wire.uploadMultiple(this.name, files, (success) => this.handleSuccessfulUpload(success), (error) => this.handleError(error), (event) => this.handleUploadProgress(event));
        } else {
            this.$wire.upload(this.name, files[0], (success) => this.handleSuccessfulUpload(success), (error) => this.handleError(error), (event) => this.handleUploadProgress(event));
        }
    },
    removeFile: function removeFile() {
        this.$wire.set(this.name, null);
        this.existingPreviewUrl = null;
    },
    removeUpload(filename) {
        this.$wire.removeUpload(this.name, filename);
    },
    dropzone: {
        ['x-on:drop']() {
            this.isDroppingFile = false;
        },
        ['x-on:drop.prevent'](event) {
            this.handleFileDrop(event);
        },
        ['x-on:dragover.prevent']() {
            this.isDroppingFile = true;
        },
        ['x-on:dragleave.prevent']() {
            this.isDroppingFile = false;
        },
        ['x-bind:class']() {
            return {
                'border-brand-500 bg-gray-5': this.isDroppingFile,
            };
        },
    },
    previewImage: {
        ['x-bind:src']() {
            return this.existingPreviewUrl;
        }
    },
    uploadInput: {
        ['x-on:change'](event) {
            this.handleFileSelect(event);
        }
    },
});
