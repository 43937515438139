import {merge} from "lodash";

export default function polarChart() {
    return {
        chart: null,

        init() {
            setTimeout(() => {
                this.draw(this.$wire);
            }, 0);
        },

        chartContainer: {
            ['@export-chart.window'](event) {
                if (this.chart.renderTo.id !== event.detail.chartId) {
                    return;
                }

                this.chart.exportChartLocal({
                    type: event.detail.type,
                });
            },
        },

        draw(component) {
            const data = component.get('data') || {};
            const customOptions = component.get('options') || {};
            let categories = {};

            if (data.length !== 0) {
                categories = data[0].categories || {};
            }

            const options = {
                chart: {
                    polar: true,
                    type: 'line',
                },
                pane: {
                    size: '80%'
                },
                xAxis: {
                    categories,
                    tickmarkPlacement: 'on',
                    lineWidth: 0,
                    labels: {
                        style: {
                            fontSize: '12px',
                            fontWeight: 500
                        }
                    }
                },
                yAxis: {
                    gridLineInterpolation: 'polygon',
                    lineWidth: 0,
                    min: 0,
                    tickInterval: 20,
                    angle: 50
                },
                tooltip: {
                    shared: true,
                    headerFormat: '<span class="chart-tooltip-series-label">{point.key}</span>',
                    pointFormat: '<span class="chart-tooltip-point"><span class="chart-tooltip-point-marker" style="color:{series.color};">&bull;</span> <span class="chart-tooltip-point-name">{series.name}</span>: <span class="chart-tooltip-point-value">{point.y:,.1f}</span></span>'
                },
                exporting: {
                    chartOptions: {
                        title: {
                            text: component.get('title') || '',
                        }
                    },
                },
                series: data,
            };

            if (this.chart) {
                this.chart.update(merge(options, customOptions));
            } else {
                this.chart = window.Highcharts.chart(this.$refs.container, merge(options, customOptions));
                this.chart.render();
            }
        }
    };
}
