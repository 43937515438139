import {merge} from "lodash";

function fetchMap(url) {
    return fetch(url)
        .then(response => response.json())
        .catch(error => {
            console.error(error);

            return {};
        });
}

export default function worldMapChart() {
    return {
        chart: null,

        init() {
            setTimeout(() => {
                this.draw(this.$wire);
            }, 0);
        },

        chartContainer: {
            ['@export-chart.window'](event) {
                if (this.chart.renderTo.id !== event.detail.chartId) {
                    return;
                }

                this.chart.exportChartLocal({
                    type: event.detail.type,
                });
            },
        },

        maps: {
            world: 'https://code.highcharts.com/mapdata/custom/world-eckert3.geo.json',
            africa: 'https://code.highcharts.com/mapdata/custom/africa.geo.json',
            asia: 'https://code.highcharts.com/mapdata/custom/asia.geo.json',
            centralAmerica: 'https://code.highcharts.com/mapdata/custom/central-america.geo.json',
            europe: 'https://code.highcharts.com/mapdata/custom/europe.geo.json',
            europeanUnion: 'https://code.highcharts.com/mapdata/custom/european-union.geo.json',
            middleEast: 'https://code.highcharts.com/mapdata/custom/middle-east.geo.json',
            nordics: 'https://code.highcharts.com/mapdata/custom/nordic-countries-core.geo.json',
            nato: 'https://code.highcharts.com/mapdata/custom/nato.geo.json',
            northAmerica: 'https://code.highcharts.com/mapdata/custom/north-america.geo.json',
            oceania: 'https://code.highcharts.com/mapdata/custom/oceania.geo.json',
            scandinavia: 'https://code.highcharts.com/mapdata/custom/scandinavia.geo.json',
            southAmerica: 'https://code.highcharts.com/mapdata/custom/south-america.geo.json'
        },

        async draw(component) {
            const customOptions = component.get('options') || {};
            const extras = component.get('extras') || {};
            const mapUrl = extras.mapType ? this.maps[extras.mapType] || this.maps.world : this.maps.world;

            const options = {
                chart: {
                    map: await fetch(mapUrl).then(response => response.json()),
                    margin: [20, 20, 100, 20],
                },
                tooltip: {
                    pointFormat: '{point.name}: <b>{point.value:.2f}%</b><br/>',
                },
                legend: {
                    verticalAlign: "bottom",
                    align: "center"
                },
                title: {
                    text: null
                },
                exporting: {
                    chartOptions: {
                        title: {
                            text: component.get('title') || '',
                        },
                        plotOptions: {
                            series: {
                                dataLabels: {
                                    enabled: false,
                                },
                            }
                        }
                    },
                },
                mapNavigation: {
                    enabled: true,
                    buttonOptions: {
                        verticalAlign: 'bottom'
                    }
                },
                series: component.get('data') || {},
            };

            if (this.chart) {
                this.chart.update(merge(options, customOptions));
            } else {
                this.chart = window.Highcharts.mapChart(this.$refs.container, merge(options, customOptions));
                this.chart.render();
            }
        }
    };
};
