import {merge} from "lodash";
import {addQuadrant, addText} from "./utilities";

export default function partnerCompatibility() {
    return {
        chart: null,

        init() {
            setTimeout(() => {
                this.draw(this.$wire);
            }, 0);
        },

        chartContainer: {
            ['@export-chart.window'](event) {
                if (this.chart.renderTo.id !== event.detail.chartId) {
                    return;
                }

                this.chart.exportChartLocal({
                    type: event.detail.type,
                });
            },
        },

        draw(component) {
            const customOptions = component.get('options') || {};
            const extras = component.get('extras') || {};

            const options = {
                chart: {
                    type: 'scatter',
                    events: {
                        render: function () {
                            const chart = this;

                            const css = {
                                fontWeight: 'bold',
                                fontSize: '13px',
                                color: '#666666',
                                zIndex: 5,
                            };

                            const quadrantNameCss = {
                                fontWeight: 'semibold',
                                fontSize: '16px',
                                color: '#000',
                                opacity: 0.3,
                                zIndex: 5,
                            };

                            const quadrantNameAttr = {
                                zIndex: 5,
                                align: 'center',
                                verticalAlign: 'middle'
                            };

                            addQuadrant(chart, 'bottomleft', -1, -100, 0, 0, {
                                fill: '#000050',
                                opacity: 0.2
                            });

                            addQuadrant(chart, 'topLeft', -1, 0, 0, 100, {
                                fill: '#31117C',
                                opacity: 0.1
                            });

                            addQuadrant(chart, 'bottomRight', 0, -100, 1, 0, {
                                fill: '#93DFE3',
                                opacity: 0.1
                            });

                            addQuadrant(chart, 'topRight', 0, 0, 1, 100, {
                                fill: '#328FFF',
                                opacity: 0.1
                            });

                            addText(chart, 'similar', extras.areaTexts.similar, -0.5, -95, css);
                            addText(chart, 'different', extras.areaTexts.different, 0.5, -95, css);
                            addText(chart, 'yourBrand', extras.areaTexts.yourBrand, -0.96, -50, css, {
                                rotation: -90,
                                zIndex: 5,
                                align: 'center'
                            });
                            addText(chart, 'yourPartner', extras.areaTexts.yourPartner, -0.96, 50, css, {
                                rotation: -90,
                                zIndex: 5,
                                align: 'center'
                            });

                            addText(chart, 'reinforcement', extras.quadrantNames.reinforcement, -0.5, 45, quadrantNameCss, quadrantNameAttr);
                            addText(chart, 'groundbreaking', extras.quadrantNames.groundbreaking, 0.5, 45, quadrantNameCss, quadrantNameAttr);
                            addText(chart, 'following', extras.quadrantNames.following, -0.5, -50, quadrantNameCss, quadrantNameAttr);
                            addText(chart, 'explorer', extras.quadrantNames.explorer, 0.5, -50, quadrantNameCss, quadrantNameAttr);

                        },
                    },
                },
                tooltip: {
                    enabled: true,
                    pointFormat: ''
                },
                xAxis: {
                    min: -1,
                    max: 1,
                    lineWidth: 0,
                    minorGridLineWidth: 0,
                    minorTickLength: 0,
                    tickLength: 0,
                    labels: {
                        enabled: false
                    },
                },
                yAxis: {
                    min: -100,
                    max: 100,
                    gridLineWidth: 0,
                    minorTicks: false,
                    majorTicks: false,
                    labels: {
                        enabled: false
                    },
                },
                plotOptions: {
                    scatter: {
                        lineWidth: 2,
                        marker: {
                            symbol: 'circle',
                            radius: 10,
                        },
                        dataLabels: {
                            format: '{point.name}',
                            enabled: true,
                            style: {
                                fontSize: '14px',
                            }
                        }
                    }
                },
                exporting: {
                    chartOptions: {
                        title: {
                            text: component.get('title') || '',
                        }
                    },
                },
                series: component.get('data') || {},
            };

            if (this.chart) {
                this.chart.update(merge(options, customOptions));
            } else {
                this.chart = window.Highcharts.chart(this.$refs.container, merge(options, customOptions));
                this.chart.render();
            }
        }
    };
}
