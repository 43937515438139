import {connectToParent} from "penpal";

export default function () {
    return {
        inIframe: false,
        supportsThirdPartyCookies: true,
        init() {
            this.checkIframe();

            if (this.inIframe && this.supportsThirdPartyCookies) {
                this.authenticate();
            } else {
                this.$refs.overlay.style.display = 'none';
            }
        },
        checkIframe() {
            this.inIframe = window !== window.parent;
        },
        authenticate() {
            connectToParent({
                parentOrigin: '*',
                debug: false,
                methods: {
                    receiveOsContext: (context) => {
                        // Left empty on purpose.
                    }
                }
            })
                .promise
                .then(({osApi}) => {
                    osApi.getAccessToken()
                        .then(accessToken => {
                            this.$wire.wppOpenAuthentication(accessToken);
                        })
                        .catch(error => {
                            this.$refs.overlay.style.display = 'none';
                            console.error('Error getting access token', error);
                        });
                })
                .catch(error => {
                    this.$refs.overlay.style.display = 'none';
                    console.info('Not running inside WPP Open.');
                });
        }
    };
}
