export default (content = '') => ({
    copied: false,
    content,
    copy() {
        let content = this.content;
        content = content.replace(/<\/p>/g, '\n');
        content = content.replace(/<[^>]*>?/gm, '');

        this.$clipboard(content);

        this.copied = true;
    }
});
