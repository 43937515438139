import {merge} from "lodash";

export default function stockChart() {
    return {
        chart: null,

        init() {
            setTimeout(() => {
                this.draw(this.$wire);
            }, 0);
        },

        chartContainer: {
            ['@export-chart.window'](event) {
                if (this.chart.renderTo.id !== event.detail.chartId) {
                    return;
                }

                this.chart.exportChartLocal({
                    type: event.detail.type,
                });
            },
        },

        draw(component) {

            const customOptions = component.get('options') || {};
            const series = component.get('data') || {};

            const options = {
                series,
                rangeSelector: {
                    selected: 1
                },
                credits: {
                    enabled: false,
                },
                tooltip: {
                    headerFormat: undefined,
                    pointFormat: '<b>{point.x:%Y-%m-%d}</b><br/><span style="color:{point.color};">•</span> {series.name}: <b>{point.y:.2f}</b><br/>',
                },
            };

            if (this.chart) {
                this.chart.update(merge(options, customOptions));
            } else {
                this.chart = window.Highcharts.stockChart(this.$refs.container, merge(options, customOptions));
                this.chart.render();
            }
        }
    };
};
