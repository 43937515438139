export default function FlourishChart(apiKey) {
    return {
        chart: null,

        init() {
            if (!apiKey) {
                console.error('Flourish API key is required');
                return;
            }

            setTimeout(() => {
                this.draw();
            }, 0);
        },

        exportChart: {
            ['@export-chart.window'](event) {
                if (this.chart.original_properties.container !== '#' + event.detail.chartId) {
                    return;
                }

                let format = 'png';

                if (event.detail.type === 'image/svg+xml') {
                    format = 'svg';
                } else if (event.detail.type === 'image/jpeg') {
                    format = 'jpg';
                }

                this.chart.snapshot({
                    download: true,
                    format: format,
                    filename: 'chart-visualization',
                }, function (error, data) {
                    if (error) {
                        console.error(error);
                        return;
                    }
                });
            },
        },

        draw() {
            const data = this.$wire.get('data') || {};
            const customOptions = this.$wire.get('options') || {};

            const defaultOptions = {
                container: '#' + this.$refs.container.id,
                api_key: apiKey,
            };

            const options = {
                ...defaultOptions,
                ...JSON.parse(JSON.stringify(customOptions)),
                data: {
                    data: JSON.parse(JSON.stringify(data)),
                },
            };

            if (this.chart) {
                this.chart.update(options);
            } else {
                this.chart = new Flourish.Live(options);
            }
        }
    };
};
