import {addLine, addText} from "./utilities";
import {merge} from "lodash";

export default function powergridChart() {
    return {
        chart: null,

        init() {
            setTimeout(() => {
                this.draw(this.$wire);
            }, 0);
        },

        chartContainer: {
            ['@export-chart.window'](event) {
                if (this.chart.renderTo.id !== event.detail.chartId) {
                    return;
                }

                this.chart.exportChartLocal({
                    type: event.detail.type,
                });
            },
        },

        draw(component) {
            const customOptions = component.get('options') || {};
            const extras = component.get('extras') || {};

            const options = {
                chart: {
                    type: 'scatter',
                    events: {
                        render: function () {
                            const chart = this;

                            const labelCss = {
                                fontWeight: 500,
                                fontSize: '13px',
                                color: '#666666'
                            };

                            addText(chart, 'leadership', extras.quadrantNames.leadership, 75, 100, labelCss);
                            addText(chart, 'massMarket', extras.quadrantNames.massMarket, 100, 53, labelCss, {
                                align: 'right'
                            });
                            addText(chart, 'fatigued', extras.quadrantNames.fatigued, 75, 2, labelCss);
                            addText(chart, 'momentum', extras.quadrantNames.momentum, 25, 100, labelCss);
                            addText(chart, 'unfocused', extras.quadrantNames.unfocused, 25, 2, labelCss);

                            addLine(chart, 'massMarketLine', 50, 50, 100, 100, {
                                'stroke-width': 1,
                                stroke: '#E7EAEE'
                            });
                        }
                    }
                },
                xAxis: {
                    min: 0,
                    max: 100,
                    tickInterval: 25,
                    startOnTick: true,
                    endOnTick: true,
                    showLastLabel: true,
                    plotLines: [
                        {
                            color: '#E7EAEE',
                            width: 1,
                            value: 50,
                        }
                    ],
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    gridLineWidth: 0,
                    tickInterval: 25,
                    plotLines: [
                        {
                            color: '#E7EAEE',
                            width: 1,
                            value: 50,
                        }
                    ],
                },
                exporting: {
                    chartOptions: {
                        title: {
                            text: component.get('title') || '',
                        }
                    },
                },
                plotOptions: {
                    scatter: {
                        lineWidth: 2,
                        marker: {
                            symbol: 'circle',
                        },
                        dataLabels: {
                            format: '{point.shortName}',
                            enabled: true,
                        },
                        tooltip: {
                            headerFormat: '<b style="font-size:14px">{point.key}</b><br>',
                            pointFormat: '<b>Brand Stature:</b> {point.x:.2f}<br> <b>Brand Strength:</b> {point.y:.2f}',
                        }
                    },
                },
                series: component.get('data') || {},
            };

            if (extras.supportsPowergridBreakdown) {
                options.plotOptions = options.plotOptions || {};
                options.plotOptions.series = options.plotOptions.series || {};
                options.plotOptions.series.cursor = 'pointer';
                options.plotOptions.series.point = {
                    events: {
                        click: function () {
                            window.Livewire.dispatch('openModal', {component: 'modals.powergrid-breakdown', arguments: {
                                filters: this.options.breakdownFilters,
                            } });
                        }
                    }
                };
            }

            if (extras.supportsGlobalBreakdown) {
                options.plotOptions = options.plotOptions || {};
                options.plotOptions.series = options.plotOptions.series || {};
                options.plotOptions.series.cursor = 'pointer';
                options.plotOptions.series.point = {
                    events: {
                        click: function () {
                            window.Livewire.dispatch('openModal', {component: 'modals.global-breakdown', arguments: {
                                filters: this.options.globalBreakdownFilters,
                                modelClass: this.options.modelClass,
                            } });
                        }
                    }
                };
            }

            if (this.chart) {
                this.chart.update(merge(options, customOptions));
            } else {
                this.chart = window.Highcharts.chart(this.$refs.container, merge(options, customOptions));
                this.chart.render();
            }
        }
    };
}
