export default (allowMultiple = false, active = []) => ({
    active,
    allowMultiple,
    isOpen(id) {
        return this.active.includes(id);
    },
    toggle(id) {
        if (this.isOpen(id)) {
            this.active = this.active.filter((activeId) => activeId !== id);
            return;
        }

        if (!this.allowMultiple) {
            this.active = [];
        }

        this.active.push(id);
    }
});
