import {addLine, addText} from "./utilities";
import {merge} from "lodash";

export default function valueChart() {
    return {
        chart: null,

        init() {
            setTimeout(() => {
                this.draw(this.$wire);
            }, 0);
        },

        chartContainer: {
            ['@export-chart.window'](event) {
                if (this.chart.renderTo.id !== event.detail.chartId) {
                    return;
                }

                this.chart.exportChartLocal({
                    type: event.detail.type,
                });
            },
        },

        draw(component) {
            const customOptions = component.get('options') || {};
            const extras = component.get('extras') || {};

            const options = {
                chart: {
                    type: 'scatter',
                    events: {
                        render: function () {
                            var chart = this;

                            addText(chart, 'priceIrrelevant', extras.quadrants.priceIrrelevant, 25, 20, {
                                fontSize: '16px',
                                width: '150px',
                                opacity: 0.2
                            });

                            addText(chart, 'aspirational', extras.quadrants.aspirational, 25, 75, {
                                fontSize: '16px',
                                width: '150px',
                                opacity: 0.2
                            });

                            addText(chart, 'balanced', extras.quadrants.balanced, 75, 75, {
                                fontSize: '16px',
                                width: '150px',
                                opacity: 0.2
                            });

                            addText(chart, 'bangForBuck', extras.quadrants.bestDeal, 75, 20, {
                                fontSize: '16px',
                                width: '150px',
                                opacity: 0.2
                            });

                            addLine(chart, 'balanceLine', 0, 0, 100, 100, {
                                opacity: 0.5,
                                stroke: '#328FFF',
                                dashstyle: 'dash'
                            });
                        }
                    }
                },
                xAxis: {
                    min: 0,
                    max: 100,
                    lineWidth: 0,
                    tickWidth: 0,
                    tickInterval: 25,
                    title: {
                        text: extras.xAxisTitle,
                    },
                    plotLines: [
                        {
                            color: '#aaa',
                            width: 1,
                            value: 50,
                        }
                    ],
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    gridLineWidth: 0,
                    tickInterval: 25,
                    title: {
                        text: extras.yAxisTitle,
                    },
                    plotLines: [
                        {
                            color: '#aaa',
                            width: 1,
                            value: 50,
                        }
                    ],
                },
                plotOptions: {
                    scatter: {
                        marker: {
                            symbol: 'circle',
                            radius: 6,
                            lineWidth: 1
                        },
                        dataLabels: {
                            format: '{point.shortName}',
                            enabled: true,
                            y: -2,
                            style: {
                                fontWeight: 500
                            }
                        },
                        tooltip: {
                            headerFormat: '<b style="font-size:14px">{point.key}</b><br>',
                            pointFormat: '<b>' + extras.categoryTitle + ':</b> {point.categoryName}<br> <b>' + extras.xAxisTitle + ':</b> {point.x:.2f}<br> <b>' + extras.yAxisTitle + ':</b> {point.y:.2f}',
                        }
                    },
                },
                exporting: {
                    chartOptions: {
                        title: {
                            text: component.get('title') || '',
                        }
                    },
                },
                series: component.get('data') || {},
            };

            if (this.chart) {
                this.chart.update(merge(options, customOptions));
            } else {
                this.chart = window.Highcharts.chart(this.$refs.container, merge(options, customOptions));
                this.chart.render();
            }
        }
    };
}
