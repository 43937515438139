import {merge} from "lodash";
import {addQuadrant, addText} from "./utilities";

export default function costOfEntryChart() {
    return {
        chart: null,

        init() {
            setTimeout(() => {
                this.draw(this.$wire);
            }, 0);
        },

        chartContainer: {
            ['@export-chart.window'](event) {
                if (this.chart.renderTo.id !== event.detail.chartId) {
                    return;
                }

                this.chart.exportChartLocal({
                    type: event.detail.type,
                });
            },
        },

        draw(component) {
            const customOptions = component.get('options') || {};

            const options = {
                chart: {
                    type: 'scatter',
                    zoomType: 'xy',
                    events: {
                        render: function () {
                            const chart = this;

                            const css = {
                                fontWeight: 'bold',
                                fontSize: '13px',
                                color: '#666666',
                                zIndex: 5,
                            };

                            addQuadrant(chart, 'entryReqBox', 75, 0, 100, chart.yAxis[0].max, {
                                fill: '#000050',
                                opacity: 0.1,
                                zIndex: 0
                            });

                            addText(chart, 'entryReqLabel', 'Basic Entry Requirements', 98, (chart.yAxis[0].max - 15), css, {
                                zIndex: 5,
                                align: 'right'
                            });
                        }
                    }
                },
                yAxis: {
                    min: 0,
                    gridLineWidth: 0
                },
                legend: {
                    enabled: false,
                },
                xAxis: {
                    gridLineWidth: 0,
                    min: 0,
                    max: 100,
                    tickInterval: 10
                },
                plotOptions: {
                    line: {
                        dataLabels: {
                            align: 'right',
                            format: '{point.name}',
                            enabled: true,
                        },
                        dashStyle: 'dash',
                    },
                    scatter: {
                        marker: {
                            symbol: 'circle',
                        },
                        dataLabels: {
                            format: '{point.name}',
                            enabled: true,
                        },
                        tooltip: {
                            headerFormat: '',
                        }
                    }
                },
                series: component.get('data') || {},
            };

            if (this.chart) {
                this.chart.update(merge(options, customOptions));
            } else {
                this.chart = window.Highcharts.chart(this.$refs.container, merge(options, customOptions));
                this.chart.render();
            }
        }
    };
}
