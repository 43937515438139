import {merge} from "lodash";

export default function scatterChart() {
    return {
        chart: null,

        init() {
            setTimeout(() => {
                this.draw(this.$wire);
            }, 0);
        },

        chartContainer: {
            ['@export-chart.window'](event) {
                if (this.chart.renderTo.id !== event.detail.chartId) {
                    return;
                }

                this.chart.exportChartLocal({
                    type: event.detail.type,
                });
            },
        },

        draw(component) {

            const customOptions = component.get('options') || {};

            const options = {
                chart: {
                    type: 'scatter',
                },
                xAxis: {
                    min: 0,
                    max: 100,
                    startOnTick: true,
                    endOnTick: true,
                    showLastLabel: true,
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    gridLineWidth: 0
                },
                exporting: {
                    chartOptions: {
                        title: {
                            text: component.get('title') || '',
                        }
                    },
                },
                plotOptions: {
                    scatter: {
                        dataLabels: {
                            format: '{point.name}',
                            enabled: true,
                        },
                        tooltip: {
                            headerFormat: '<b style="font-size:14px">{point.key}</b><br>',
                            pointFormat: '<b>X:</b> {point.x:.2f}<br> <b>Y:</b> {point.y:.2f}',
                        }
                    },
                },
                series: component.get('data') || {},
            };

            if (this.chart) {
                this.chart.update(merge(options, customOptions));
            } else {
                this.chart = window.Highcharts.chart(this.$refs.container, merge(options, customOptions));
                this.chart.render();
            }
        }
    };
}
