export function initializeDynamicVideo({projectId, scenes = {}, rest = {}, loadingText = 'Loading...'}) {
    BlingsPlayer.create({
        project: {
            id: projectId
        },
        data: {},
        scenes,
        settings: {
            container: document.getElementById("video-container"),
            cinematic: false,
            showTimeline: true,
            storiesMode: false,
            showStoriesModeIndicators: false,
            showStoriesModeIndicatorsText: false,
            hideFullScreenBtn: false,
            muted: false,
            autoplay: false,
            autoReplay: false,
            autoFullScreen: false,
            autoFocus: true,
            loadingText,
            colors: {
                loader: "#C2D4FF",
                ctrlBtns: "#ffffff",
                rail: "#ffffff",
                progress: "#0A2FFF",
                thumb: "#85A3FF",
                bg: "#000000"
            }
        },
        rest,
    });
}

export function voteForQuestion(questionKey = null, answerId = null) {
    return new Promise((resolve, reject) => {
        const data = window.Livewire.first().call('handleSurveyVote', questionKey, answerId);

        resolve(data);
    });
}
