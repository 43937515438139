import {merge} from "lodash";

export default function columnChart() {
    return {
        chart: null,

        init() {
            setTimeout(() => {
                this.draw(this.$wire);
            }, 0);
        },

        chartContainer: {
            ['@export-chart.window'](event) {
                if (this.chart.renderTo.id !== event.detail.chartId) {
                    return;
                }

                this.chart.exportChartLocal({
                    type: event.detail.type,
                });
            },
        },

        draw(component) {
            const customOptions = component.get('options') || {};

            const options = {
                chart: {
                    type: 'column',
                },
                xAxis: {
                    type: 'category',
                    crosshair: false,
                },
                yAxis: {
                    min: 0,
                    max: 100,
                    visible: true,
                    title: {
                        enabled: false,
                    }
                },
                tooltip: {
                    shared: true,
                },
                exporting: {
                    chartOptions: {
                        title: {
                            text: component.get('title') || '',
                        }
                    },
                },
                plotOptions: {
                    series: {
                        grouping: true,
                        borderWidth: 0,
                    },
                    column: {
                        pointPadding: 0.1,
                        borderRadius: 10,
                    }
                },
                series: component.get('data') || {},
            };

            if (this.chart) {
                this.chart.update(merge(options, customOptions));
            } else {
                this.chart = window.Highcharts.chart(this.$refs.container, merge(options, customOptions));
                this.chart.render();
            }
        }
    };
}
