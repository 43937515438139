export default () => ({
    init() {
        this.$nextTick(() => {
            this.$wire.hiddenDataSeries.forEach(seriesId => {
                this.$dispatch('toggle-series', {seriesId, visible: false});
            });
        });
    },
    toggleSeries(seriesId) {
        const index = this.$wire.hiddenDataSeries.indexOf(seriesId);
        const visible = index === -1;

        if (visible) {
            this.$wire.hiddenDataSeries.push(seriesId);
        } else {
            this.$wire.hiddenDataSeries.splice(index, 1);
        }

        this.$dispatch('toggle-series', {seriesId, visible: !visible});
    },
    isHidden(seriesId) {
        return this.$wire.hiddenDataSeries.includes(seriesId);
    },
    highlightSeries(seriesId, active) {
        this.$dispatch('highlight-series', {seriesId, highlighted: active});
    },
    unhighlightSeries(seriesId) {
        this.highlightSeries(seriesId, false);
    }
});

